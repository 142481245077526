















































import Vue from "vue";
import SneezleMap from "@/components/SneezleMap.vue";
import FAQs from "@/components/FAQs.vue";
import About from "@/components/About.vue";

import SneezlesFooter from "@/SneezlesFooter.vue";
import GetInvolved from "@/GetInvolved.vue";
import Privacy from "@/Privacy.vue";

export default Vue.extend({
  name: "App",
  components: {
    Privacy,
    GetInvolved,
    SneezlesFooter,
    About,
    FAQs,
    SneezleMap,
  },
  data: () => ({
    privacy: false,
  }),
});
