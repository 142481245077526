


















































































export default {
  name: "FAQs",
};
