

































import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiEmail,
  mdiGithub,
  mdiMoleculeCo2,
  mdiTwitter,
  mdiYoutube,
} from "@mdi/js";

export default {
  name: "SneezlesFooter",
  components: { SvgIcon },
  data: () => ({
    mdiEmail,
    mdiGithub,
    mdiMoleculeCo2,
    mdiTwitter,
    mdiYoutube,
  }),
};
