




















































import Vue from "vue";

// Typescript sad-times https://stackoverflow.com/q/52109471/361867
export type VForm = Vue & { validate: () => boolean };

export default Vue.extend({
  name: "GetInvolved",
  data: () => ({
    emailAddress: "",
    spinSubmitButton: false,
    snackbar: false,
    emailRules: [
      (v: string) => !!v || "E-mail is required",
      (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
  },
  methods: {
    signUp() {
      if (this.form.validate()) {
        this.spinSubmitButton = true;
        const spinTime = 700;
        setTimeout(() => (this.spinSubmitButton = false), spinTime);
        setTimeout(() => (this.snackbar = true), spinTime);
        fetch(`${process.env.BASE_URL}/signup/${this.emailAddress}`);
      }
    },
  },
});
