










































































































































































































































export default {
  name: "Privacy",
  props: {
    privacy: {},
  },
};
